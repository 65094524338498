import {useSpring, animated} from 'react-spring'
import {convertTemp, getLocalTime} from '../../HelperFunctions'
import {FaArrowCircleUp} from 'react-icons/fa'

import '../../styles/Daily/DayDetails.css'



const DayDetails = ({date, details, useF, offset}) => {
    const props = useSpring({delay:400, to:{opacity:1, display:'block'}, from: {opacity:0, display:'none'}})

    // used to rotate the wind direction icon to the correct cardinal direction
    const windDirRotation = 'rotate(' + details.wind_deg + 'deg)'

    return(

            <animated.div style={props}>
                <div className='day-details-date-and-description'>
                    <div className = 'day-details-date'>{date}:</div>
                    <div className='day-details-description'>{details.weather[0].description} </div>
                </div>

                <div className='day-details-columns'>
                    <div className='day-details-kvps'>
                        <div>
                            <div className='day-details-key'>High: </div>
                            <div className='day-details-key'>Low: </div>
                            <div className='day-details-key'>Morn: </div>
                            <div className='day-details-key'>Day: </div>
                            <div className='day-details-key'>Night: </div>
                        </div>
                        <div>
                            <div className='day-details-value'>{convertTemp(details.temp.max, useF)}&#730; </div>
                            <div className='day-details-value'>{convertTemp(details.temp.min, useF)}&#730; </div>
                            <div className='day-details-value'>{convertTemp(details.temp.morn, useF)}&#730; </div>
                            <div className='day-details-value'>{convertTemp(details.temp.day, useF)}&#730; </div>
                            <div className='day-details-value'>{convertTemp(details.temp.night, useF)}&#730; </div>
                        </div>
                    </div>

                    <div className='day-details-kvps'>
                        <div>
                            <div className='day-details-key'>Sunrise: </div>
                            <div className='day-details-key'>Sunset: </div>
                            <div className='day-details-key'>Rain: </div>
                            <div className='day-details-key'>Hum: </div>
                            <div className='day-details-key'>Wind: </div>
                        </div>
                        <div>
                            <div className='day-details-value'>{getLocalTime(details.sunrise, offset)}</div>
                            <div className='day-details-value'>{getLocalTime(details.sunset, offset)}</div>
                            <div className='day-details-value'>{(!details.pop) ? '0': Math.round(details.pop * 100)}%</div>
                            <div className='day-details-value'>{details.humidity}%</div>
                            <div className='day-details-value wind-values' > 
                                {Math.round(details.wind_speed *  2.237)} mph 
                                <div className='wind-arrow' >
                                    <FaArrowCircleUp style={{transform: windDirRotation}} />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </animated.div>
        
    )
}


export default DayDetails