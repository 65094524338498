import DaySnippet from './DaySnippet'
import DynamicCarousel from '../Navigation/DynamicCarousel'
import {BsCaretLeft,BsCaretRight} from 'react-icons/bs'

import '../../styles/Daily/DailyForecast.css'

const DailyForecast = ({isMobile, dailyForecast, useF, onMouseOut, onMouseIn}) => {

    if(!isMobile){
        return(
            <DynamicCarousel 
                children={dailyForecast.map((day, index) => {let date = new Date(); date.setDate(date.getDate() + index); return <DaySnippet isMobile={isMobile} date={date} day={day} key={day.dt} useF={useF} onMouseOut={() => onMouseOut(-1)} onMouseIn={() => onMouseIn(index)}/> })}
                breakPoints={[{break: 992, childrenNum: 8}, {break: 425, childrenNum: 4}, {break: 0, childrenNum: 3}]}
                buttons={{prev: <BsCaretLeft className='forecast-control' />, next: <BsCaretRight className='forecast-control' />}}
            />
        )
    } else {
        return(
            <div className='mobile-daily-forecast' onMouseLeave={() => onMouseOut(-1)} >
                {dailyForecast.map((day, index) => {let date = new Date(); date.setDate(date.getDate() + index); return <DaySnippet isMobile={isMobile} date={date} day={day} key={day.dt} useF={useF} onMouseOut={() => onMouseOut(-1)} onMouseIn={() => onMouseIn(index)}/> })}
            </div>
        )
    }
}

export default DailyForecast