import {useEffect, useRef, useState} from 'react'
import '../../styles/Navigation/GoogleInput.css'

const GoogleInput = ({searchButtonRef, onChange, onSelect}) => {

    const inputRef = useRef(null)

    const[googleScriptLoaded, setGoogleScriptLoaded] = useState(false);

    useEffect(() => {

        if(window.google){
            setGoogleScriptLoaded(true);
        } else {
            window.addEventListener('load', () => setGoogleScriptLoaded(true));        
        }

        inputRef.current.addEventListener("keyup",  (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                searchButtonRef.current.click();
            }
          });

    },[searchButtonRef]);

    useEffect(() => {
        if(!googleScriptLoaded){
            return;
        }

        const options = {types: ["(cities)"]}
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, options);
        autocomplete.setFields(['formatted_address'])
        autocomplete.addListener('place_changed', () => {if(autocomplete.getPlace().hasOwnProperty('formatted_address')) {onSelect(autocomplete.getPlace().formatted_address)}});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[googleScriptLoaded])

    return (
        <input placeholder="Enter city or zip" className='form-control form-control-lg' id='search' onChange={(e) => onChange(e.target.value)} ref={inputRef} />
    )
}

export default GoogleInput