import {useState, useEffect} from 'react'

// window.google = window.google ? window.google : {}

export default function useGeocoder(){

    const[geocoder, setGeocoder] = useState(null);
    // const[googleScriptLoaded, setGoogleScriptLoaded] = useState(false);


    useEffect(() => {

        if(window.google){
            setGeocoder(new window.google.maps.Geocoder());
        } else {
            window.addEventListener('load', () => {
                const coder = new window.google.maps.Geocoder();
                setGeocoder(coder);                
            });
        }

    },[])

    // useEffect(() => {
    //     if(!googleScriptLoaded){
    //         return;
    //     }

    //     setGeocoder(new window.google.maps.Geocoder());

    // },[googleScriptLoaded])

    const getCoordinatesString = async (cityName) => {
        const result = await new Promise(function(resolve, reject) {
            geocoder.geocode( { 'address': cityName}, function(results, status) {
                if (status === 'OK') {
                    resolve({name:results[0].formatted_address, coordinates:"lat=" + results[0].geometry.location.lat() + "&lon=" + results[0].geometry.location.lng()});
                } else {
                    reject(null);
                }
            });
        })  
        return result;
    }

    return(getCoordinatesString);
}