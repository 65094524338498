import Container from 'react-bootstrap/Container'
import CityWeather from './CityWeather'
import MyNavBar from './Navigation/MyNavBar'

import useSavedCities from '../hooks/useSavedCities'
import useSearchCity from '../hooks/useSearchCity'
import useSetUnit from '../hooks/useSetUnit'
import useDetectMobile from '../hooks/useDetectMobile'

import {FaCloud} from 'react-icons/fa'

import '../styles/WeatherApp.css'

const WeatherApp = () => {

    const [savedCitiesJSON, saveCity, removeSavedCity] = useSavedCities();
    const [currentCity, clearCurrentCity, fetchCurrentCity, onSearch, onSearchTextChange] = useSearchCity();
    const [useFarenheit, savePreferredUnit] = useSetUnit();
    const isMobile = useDetectMobile();


    const renderSavedCity = (cityJSON) => {
        return <CityWeather isExpanded={false} isSaved={true} onSave={() => saveCity(currentCity, clearCurrentCity)} isMobile={isMobile} useF={useFarenheit} key={cityJSON.name} name={cityJSON.name} weather={cityJSON.weather} onRemove={() => removeSavedCity(cityJSON.name)} />
    }

    const renderCurrentCity = () => {
        return <CityWeather isExpanded={true} isSaved={false} onSave={() => saveCity(currentCity, clearCurrentCity)} isMobile={isMobile} useF={useFarenheit} key={currentCity.name} name={currentCity.name} weather={currentCity.weather} onRemove={() => removeSavedCity(currentCity.name)} /> 
    }

    return (

        <Container id='top-level-container'>
            <MyNavBar searchOnChange = {onSearchTextChange} searchOnSelect = {onSearch} searchButtonOnClick = {fetchCurrentCity} unitOnToggle = {savePreferredUnit} useF={useFarenheit}/>
            
            {renderCurrentCity()}
            
            {(savedCitiesJSON.length > 0 || currentCity.weather !== null) ? 
                    savedCitiesJSON.map((cityJSON) => renderSavedCity(cityJSON))
                : 
                    <div id='no-saved-weather-tip'>
                        (Search for weather above) &nbsp; <FaCloud />
                    </div>
            } 
        </Container>
    )
}

export default WeatherApp