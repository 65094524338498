import WeatherApp from './components/WeatherApp.js'

const App = () => {

  return (
    <WeatherApp />
  );
}

export default App;
