import '../../styles/Hourly/HourDetails.css'
import {useSpring, animated} from 'react-spring'
import{convertTemp, getLocalTime} from '../../HelperFunctions'
import {FaArrowCircleUp} from 'react-icons/fa'

const HourDetails = ({offset, details, useF}) => {

    const windDirRotation = 'rotate(' + details.wind_deg + 'deg)'

    const props= useSpring({delay:400, to:{opacity:1, display:'block'}, from: {opacity:0, display:'none'}})
    return (
            <animated.div style={props}>
                <div className='hour-details-date-and-description'>
                    <div className = 'hour-details-date'>{getLocalTime(details.dt, offset)}:</div>
                    <div className='hour-details-description'>{details.weather[0].description} </div>
                </div>

                <div className='hour-details-columns'>
                    <div className='hour-details-kvps'>
                        <div>
                            <div className='hour-details-key'>Temp: </div>
                            <div className='hour-details-key'>Rain: </div>
                            <div className='hour-details-key'>Hum: </div>
                            <div className='hour-details-key'>Wind: </div>
                        </div>
                        <div>
                            <div className='hour-details-value'>{convertTemp(details.temp, useF)}&#730;</div>
                            <div className='hour-details-value'>{(!details.pop) ? '0': Math.round(details.pop * 100)}%</div>
                            <div className='hour-details-value'>{details.humidity}%</div>
                            <div className='hour-details-value hour-wind-values' > 
                                {Math.round(details.wind_speed *  2.237)} mph 
                                <div className='hour-wind-arrow' >
                                    <FaArrowCircleUp style={{transform: windDirRotation}} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='hour-details-kvps'>
                        <div>
                            <div className='hour-details-key'>Feels Like: </div>
                            <div className='hour-details-key'>Pressure: </div>
                            <div className='hour-details-key'>UV Index: </div>
                            <div className='hour-details-key'>Cloudiness: </div>
                        </div>
                        <div>
                            <div className='hour-details-value'>{convertTemp(details.feels_like, useF)}&#730;</div>
                            <div className='hour-details-value'>{details.pressure}<sub>hPa</sub></div>
                            <div className='hour-details-value'>{details.uvi}</div>
                            <div className='hour-details-value'>{(!details.clouds) ? '0': details.clouds}%</div>
                        </div>
                    </div>
                    
                </div>
            </animated.div>
    )
}

export default HourDetails