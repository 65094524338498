import '../../styles/Daily/DaySnippet.css'

const DaySnippet = ({isMobile, date, day, useF, onMouseIn, onMouseOut}) => {

    return(
        <div className={isMobile ? 'mobile-day-snippet' : 'day-snippet'} onClick={onMouseIn} onMouseEnter={isMobile ? null : onMouseIn} onMouseLeave={isMobile ? null : onMouseOut} >
            <div>{date.toString().substring(0,3)}</div>
            <img className='daily-icon' src= {"https://openweathermap.org/img/wn/" + day.weather[0].icon + "@2x.png"} alt="" />
            <div className='daily-snippet-high-low'>
                <div className='daily-snippet-high'>{(useF === 'true') ? Math.round(1.8 * (day.temp.max - 273) + 32) : Math.round(day.temp.max - 273.15) }&#730;&nbsp; </div>
                <div className='daily-snippet-low'>{(useF === 'true') ? Math.round(1.8 * (day.temp.min - 273) + 32) : Math.round(day.temp.min - 273.15) }&#730;</div>
            </div>
        </div>
    )
}

export default DaySnippet