// *DYNAMIC CAROUSEL*
//      A carousel that changes the number of displayed children based on provided screen width breakPoints
//


// PROPERTIES: 
//      children: A list of JSX components to be displayed by the carousel
//      buttons: An object with 2 JSX component attributes (prev,next) for the carousel controls
//      breakPoints: An array of BreakPoint objects that determines how many children are displayed at different screen sizes.
//                   Each BreakPoint has 2 attributes: break, childrenNum
//                   break: a screen width boundary    childrenNum: number of children to display while screen width > break
//                ** breakPoints must be sorted in descending order by break. The final element's break must be 0


// breakPoints: 
//      if breakpoints looked like this: [{break: 500, childrenNum: 3},{break: 400, childrenNum: 2],{break: 0, childrenNum: 1}]  
//          3 children will be displayed when screen width > 500. 
//          2 children when 500 > screen width > 300. 
//          1 child when 400 > screen width > 0

// buttons: 
//      buttons might look like this: {prev: <button> prev </button> next: <button> next </button>}
//          DO NOT set an onClick for These buttons. They will be inserted into a div with an onClick handler

// children: 
//      just a list of components. if child state needs to be shared, just keep it at a higher level like usual. 

import {useState, useEffect, useRef} from 'react';
 
const DynamicCarousel = ({children, buttons, breakPoints}) => {
    
    const [startChild, setStartChild] = useState(0);

    let startChildRef = useRef(startChild);
    const setStartChildRef = newStart => {
        setStartChild(newStart);
        startChildRef.current = newStart;
    };

    const [childrenNum, setChildrenNum] = useState(() => {
        const width = window.innerWidth;
        for(let i = 0; i < breakPoints.length; i++){
            if(width > breakPoints[i].break){
                return breakPoints[i].childrenNum;
            }
        }
    });

    let childrenNumRef = useRef(childrenNum);
    const setChildrenNumRef = newChildrenNum => {
        setChildrenNum(newChildrenNum);
        childrenNumRef.current = newChildrenNum;
    };

 
    const incInterval = () => {
        if((startChild + childrenNum) <= children.length){
            setStartChildRef(Math.min(startChild + childrenNum, children.length - childrenNum))

        }
    }

    const decInterval = () => {
        if(startChild !== 0){
            setStartChildRef(Math.max(startChild - childrenNum, 0))

        }
    }
 
    const handleResize = () => {
            const width = window.innerWidth;
            for(let j = 0; j < breakPoints.length; j++){
                if(width > breakPoints[j].break ){
                    if(childrenNumRef.current === breakPoints[j].childrenNum ){
                        return;
                    }

                    setStartChildRef(Math.min(startChildRef.current, children.length - breakPoints[j].childrenNum));
                    setChildrenNumRef(breakPoints[j].childrenNum);
                    
                    return;
                }
            }
    }
    
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.addEventListener("resize", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 
    return (
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <div className={(startChild === 0) ? 'invisible' : ''} onClick={decInterval}>{buttons.prev}</div>
            {children.slice(Math.min(startChild, children.length - childrenNum), startChild + childrenNum)}
            <div className={(startChild + childrenNum >= children.length) ? 'invisible' : ''} onClick={incInterval}>{buttons.next}</div> 
        </div>
    )
}
 
export default DynamicCarousel