import HourSnippet from './HourSnippet'
import {BsCaretLeft,BsCaretRight} from 'react-icons/bs'
import DynamicCarousel from '../Navigation/DynamicCarousel'

import '../../styles/Hourly/HourlyForecast.css'

const HourlyForecast = ({isMobile, hourly, useF, offset, onMouseIn, onMouseOut}) => {

    if(!isMobile){
        return(
            <DynamicCarousel 
                children={hourly.map((hour, index) => <HourSnippet key={hour.dt} onMouseOut={() => onMouseOut(-1)} onMouseIn={() => onMouseIn(index)} hour={hour} useF={useF} offset={offset}/>)}
                breakPoints={[{break: 767, childrenNum: 6}, {break: 0, childrenNum: 3}]}
                buttons={{prev: <BsCaretLeft className='forecast-control' />, next: <BsCaretRight className='forecast-control' />}}
            />
        )
    } else {
        return(
            <div onMouseLeave={() => onMouseOut(-1)} className='mobile-hourly-forecast' >
                {hourly.map((hour, index) => <HourSnippet isMobile={isMobile} onMouseOut={() => onMouseOut(-1)} onMouseIn={() => onMouseIn(index)} hour={hour} useF={useF} offset={offset}/>)}
            </div>
        )
    }
    
}

export default HourlyForecast