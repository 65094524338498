import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'

import {FaCloud} from 'react-icons/fa'

import {useState, useEffect, useRef} from 'react'

import GoogleInput from './GoogleInput'

import '../../styles/Navigation/MyNavBar.css'

const MyNavBar = ({searchOnChange, searchOnSelect, searchButtonOnClick, unitOnToggle, useF}) => {

    const searchButtonRef = useRef(null);

    const [hide, setHide] = useState(false)
    let isHidden = useRef(hide);

    const setHidden = () => {
        isHidden = !isHidden;
        setHide(isHidden);
    }

    const handleScroll = () => {
        if(window.pageYOffset > 50 && !isHidden){
            setHidden();
        } else if(window.pageYOffset < 50 && isHidden) {
            setHidden();
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.addEventListener("scroll", null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return(
        <>
        {!hide &&
            <Navbar fixed="top" bg="light" expand='lg'>
                <Navbar.Brand>
                    <FaCloud className="brand"></FaCloud>
                    FreeForecast
                </Navbar.Brand>
                <ToggleButtonGroup type="radio" name="units" >
                    <ToggleButton onClick={() => unitOnToggle('false')} type='radio' name="units" variant={useF === 'true' ? "outline-dark" : "dark" }>&#730;C</ToggleButton>
                    <ToggleButton onClick={() => unitOnToggle('true')} type='radio' name="units" variant={useF === 'true'  ? "dark" : "outline-dark"}>&#730;F</ToggleButton>
                </ToggleButtonGroup>
                
                    <div id='search-input-and-submit' >
                        <GoogleInput searchButtonRef={searchButtonRef} onEnter = {searchButtonOnClick} onChange = {searchOnChange} onSelect = {searchOnSelect} />
                        <Button ref={searchButtonRef} style={{marginLeft:'15px'}} variant="dark" onClick={searchButtonOnClick}>Get Weather</Button>
                    </div>
            </Navbar>
        }
        </>
    )
}

export default MyNavBar