import '../../styles/Hourly/HourSnippet.css'

const HourSnippet = ({isMobile, hour, useF, offset, onMouseIn, onMouseOut}) => {

    let hourInt = ((hour.dt + offset) / 3600) % 24
    const amOrPm = (hourInt > 11) ? 'pm' : 'am'
    hourInt = hourInt % 12;
    if(hourInt === 0){hourInt = 12}

    const hourString = hourInt + amOrPm

    let hourSnippetClassName = (isMobile) ? 'mobile-hour' : 'hour'
    if(hourString === '12am'){
        hourSnippetClassName += ' midnight-hour'
    }

    return(
        <div className={hourSnippetClassName} onClick={onMouseIn} onMouseEnter={isMobile ? null : onMouseIn}  onMouseLeave={isMobile ? null : onMouseOut}>
            {hourString === '12am' && 
                <div className='hour-time'> 
                    <div className='midnight-hour-time-int'> {hourInt} </div>
                    <div className='midnight-hour-time-am-pm'>{amOrPm}</div> 
                </div>}
            {hourString !== '12am' && 
                <div className='hour-time'> 
                    <div className='hour-time-int'> {hourInt} </div>
                    <div className='hour-time-am-pm'>{amOrPm}</div> 
                </div>}

            {(useF === 'true') ? Math.round(1.8 * (hour.temp - 273) + 32) : Math.round(hour.temp - 273.15) } 
            <sup className='hour-temp-degree'>&#730;</sup>
            <sup className='hour-temp-unit'>{(useF === 'true') ? 'F' : 'C'}</sup>
        </div>
    )
}

export default HourSnippet