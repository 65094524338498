import {useState} from 'react'

export default function useSetUnit(){
    const[useFarenheit, setUseFarenheit] = useState((localStorage.getItem('useFarenheit') === null) ? 'true' : (localStorage.getItem('useFarenheit')));

    const savePreferredUnit = (useFarenheit) => {
        setUseFarenheit(useFarenheit);
        localStorage.setItem('useFarenheit', useFarenheit);
    }
    return [useFarenheit, savePreferredUnit]
}