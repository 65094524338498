import {useEffect, useState, useRef} from 'react'

export default function useSavedCities(){

    const isMounted = useRef(true);

    // saved cities are stored as {name: (cityName), coordinates: (coordinates)}
    const[savedCities, setSavedCities] = useState((localStorage.getItem('savedCities') === null) ? [] : JSON.parse(localStorage.getItem('savedCities')))
    // saved cities JSON is stored as {name: (cityName), weather: (weather)}
    const[savedCitiesJSON, setSavedCitiesJSON] = useState([])

    useEffect(() => {
        fetchAndSetSavedCities();
        return () => {isMounted.current = false};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const fetchAndSetSavedCities = async () => {
        const savedWeatherJSON = await fetchSavedCities();
            if(isMounted.current){
                setSavedCitiesJSON(savedWeatherJSON);
            }
    }

    const fetchSavedCities = async () => {
        const savedCitiesWeatherJSON =  await Promise.all(savedCities.map(async (savedCity) => {return await fetchSavedCity(savedCity)}));
        return savedCitiesWeatherJSON;
    }

    const fetchSavedCity = async (savedCity) => {
        const weatherJSON = await fetchWeatherByCoordinates(savedCity.coordinates)
        return {name:savedCity.name, weather:weatherJSON}
    }

    const fetchWeatherByCoordinates = async (coordinates) => {
        const res = await fetch('https://api.openweathermap.org/data/2.5/onecall?' + coordinates + '&exclude=minutely&appid=9035a183aea3ebf0f7fe2c28dc04c7b3')
        const weatherJSON = await res.json()
        return weatherJSON;
    } 

    const saveCity = async (currentCityFull, clearCurrentCity) => { 
        if(savedCities.filter(savedCity => savedCity.coordinates === currentCityFull.coordinates).length === 0){
            const newSavedCities = [{name:currentCityFull.name, coordinates:currentCityFull.coordinates}, ...savedCities]
            setSavedCities(newSavedCities)
            localStorage.setItem('savedCities', JSON.stringify(newSavedCities))
            setSavedCitiesJSON([{name:currentCityFull.name, weather:currentCityFull.weather}, ...savedCitiesJSON])
            clearCurrentCity();
        } else {
            alert("City Already Saved")
        }
    }  

    const removeSavedCity = (savedCityName) => {
        const newSavedCitiesJSON = savedCitiesJSON.filter(cityJSON => (cityJSON.name !== savedCityName));
        setSavedCitiesJSON(newSavedCitiesJSON);
        const newSavedCities = savedCities.filter(city => city.name !== savedCityName);
        setSavedCities(newSavedCities);
        localStorage.setItem('savedCities', JSON.stringify(newSavedCities));

    }  

    return [savedCitiesJSON, saveCity, removeSavedCity];
}