import {useState} from 'react'
import ToolTip from './Navigation/ToolTip'

import DailyForecast from './Daily/DailyForecast'
import HourlyForecast from './Hourly/HourlyForecast'
import HourDetails from './Hourly/HourDetails'
import DayDetails from './Daily/DayDetails'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'

import {FaExpandAlt, FaRegHeart} from 'react-icons/fa'
import { AiFillDelete } from "react-icons/ai";

import {getDate} from '../HelperFunctions'

import '../styles/CityWeather.css'

const CityWeather = ({isMobile, weather, onRemove, name, useF, isSaved, onSave, isExpanded}) => {

    const[forecastType, setForecastType] = useState('daily')
    const[detailShown, setDetailShown] = useState(-1)
    const[expanded, setExpanded] = useState(isExpanded)

    if(weather != null){
        const selectedForecast = (forecastType === 'daily') ? 
                    <DailyForecast isMobile={isMobile} dailyForecast={weather.daily} useF={useF} onMouseOut={setDetailShown} onMouseIn={setDetailShown} />
                :   <HourlyForecast isMobile={isMobile} onMouseOut={setDetailShown} onMouseIn={setDetailShown} hourly={weather.hourly} useF={useF} offset={weather.timezone_offset}/>;

        const selectedDetails = (forecastType === 'daily') ? 
                    <DayDetails date={getDate(detailShown)} useF={useF} details={weather.daily[detailShown]} offset={weather.timezone_offset}/>
                :   <HourDetails date={getDate(detailShown)} useF={useF} details={weather.hourly[detailShown]} offset={weather.timezone_offset} />;

        
        return (
            <div className={isMobile ? 'mobile-city-border' : 'city-border'} onClick={() => {if(!expanded){setExpanded(true)}}}>                
                <div className='city-name-and-buttons' >
                    <div className='city-name'>
                        {name}
                    </div>

                    {isSaved &&
                        <div className='saved-city-buttons' >
                            {expanded && 
                                <ToolTip  placement='left' text='Unsave'>
                                    <div className='saved-city-remove' onClick={onRemove}> <AiFillDelete /> </div>
                                </ToolTip>}
                            <div className='saved-city-expand' onClick={() => setExpanded(!expanded)}><FaExpandAlt /></div>
                        </div>
                    }

                    {!isSaved &&
                        <ToolTip  placement='left' text='Favorite'>
                            <FaRegHeart onClick={onSave} id='save-city-button'/>
                        </ToolTip>
                    }
                </div>

                <div className='saved-city-temp-description'>
                    <div className='temp'>{(useF === 'true') ? Math.round(1.8 * (weather.current.temp - 273) + 32) : Math.round(weather.current.temp - 273.15) }  
                        <sup className='degree'>&#730; {(useF === 'true') ? 'F' : 'C'}</sup> 
                    </div>

                    <div className='description'>
                        {weather.current.weather[0].description}
                    </div>
                </div>

                {expanded &&
                    <>
                        <img alt='' className='icon' width='70' height='70' src= {"https://openweathermap.org/img/wn/" + weather.current.weather[0].icon + "@2x.png"}/> 
                            
                        <ToggleButtonGroup type="radio" name="units" >
                            <ToggleButton onTouchStart={(isMobile) ? () => {setForecastType('daily'); setDetailShown(-1);} : null} onClick={(isMobile) ? null : () => setForecastType('daily')} type='radio' name="units" variant={forecastType === 'hourly' ? "outline-dark" : "dark" }>Daily</ToggleButton>
                            <ToggleButton onTouchStart={(isMobile) ? () => {setForecastType('hourly'); ; setDetailShown(-1);} : null} onClick={(isMobile) ? null : () => setForecastType('hourly')} type='radio' name="units" variant={forecastType === 'hourly'  ? "dark" : "outline-dark"}>Hourly</ToggleButton>
                        </ToggleButtonGroup>

                        {selectedForecast}
                        {detailShown !== -1 && selectedDetails}
                    </>
                }
            </div>
        )
    }else {
        return <></>
    }
}

export default CityWeather