import useGeocoder from '../hooks/useGeocoder'
import {useState, useRef} from 'react'

export default function useSearchCity(){

    const getCoordinatesString = useGeocoder();

    const isMounted = useRef(true);

    const[currentSearch, setCurrentSearch] = useState('')
    const[currentCity, setCurrentCity] = useState({name: null, weather: null, coordinates: null});

    const fetchWeatherByCoordinates = async (coordinates) => {
        const res = await fetch('https://api.openweathermap.org/data/2.5/onecall?' + coordinates + '&exclude=minutely&appid=9035a183aea3ebf0f7fe2c28dc04c7b3')
        const weatherJSON = await res.json()
        return weatherJSON;
    } 

    const fetchCurrentCity = async () => {
        const cityNameAndCoordinates = await getCoordinatesString(currentSearch);
        const coordinatesString = cityNameAndCoordinates.coordinates
        const newCityJSON = await fetchWeatherByCoordinates(coordinatesString);
        if(isMounted.current){
            setCurrentCity({name: cityNameAndCoordinates.name, weather: newCityJSON, coordinates: formatCoordinateString(newCityJSON.lat, newCityJSON.lon)})
        }
    } 

    const formatCoordinateString = (lat, lon) => {
        return ("lat=" + lat + '&lon=' + lon);
    }

    const onSearchTextChange = (place) => {
        setCurrentSearch(place);
    }

    const onSearch = async (place) => {
        setCurrentSearch(place);
        const cityNameAndCoordinates = await getCoordinatesString(place);
        const coordinatesString = cityNameAndCoordinates.coordinates;
        const cityJSON = await fetchWeatherByCoordinates(coordinatesString);

        setCurrentCity({name: cityNameAndCoordinates.name, weather: cityJSON, coordinates: formatCoordinateString(cityJSON.lat, cityJSON.lon)})
    }

    const clearCurrentCity = () => {
        setCurrentCity({name: null, weather: null, coordinates: null});
    }

    return [currentCity, clearCurrentCity, fetchCurrentCity, onSearch, onSearchTextChange];
}