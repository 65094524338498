import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const ToolTip = ({children, placement, text}) => {    

    const renderTooltip = (props) => (
        <Tooltip id="tooltip" {...props}>
            {text}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement={placement}
            delay={{ show: 400, hide: 200 }}
            overlay={renderTooltip}
        >
            {children}
        </OverlayTrigger>
    )
}

export default ToolTip