export function convertTemp(temp, useFString){
    return (useFString === 'true') ? Math.round(1.8 * (temp - 273) + 32) : Math.round(temp - 273.15) 

}

export function getLocalTime(dt, offset){
    let hourInt = Math.floor(((dt + offset) / 3600)) % 24;
    let minuteInt = Math.round(((dt + offset) / 60)) % 60;
    if(minuteInt < 10){ minuteInt = '0' + minuteInt }

    let amOrPm = (hourInt > 11) ? ' pm' : ' am';
    hourInt = hourInt % 12;
    if(hourInt === 0){hourInt = 12}

    return hourInt + ':' + minuteInt + amOrPm
}

export function getDate(index){
    let date = new Date(); 
    date.setDate(date.getDate() + index);
    let dateArray = date.toString().split(" ");
    return dateArray[0] + ' ' + dateArray[1] + ' ' + dateArray[2];
}
